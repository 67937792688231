<template>
    <v-form ref="form" v-model="valid" lazy-validation @submit.prevent="validate">
        <v-layout wrap v-if="item">
            <v-flex xs12 mt-6>
                <v-layout wrap>
                    <v-flex xs12 sm6 py-0>
                        <v-text-field :label="$t('Link')" v-model="item.link" :rules="[rules.required, rules.linkName]" dense />
                    </v-flex>
                    <v-flex xs12 sm6 py-0>
                        <v-text-field :label="$t('Nome')" v-model="item.name" :rules="[rules.required]" dense />
                    </v-flex>
                </v-layout>
            </v-flex>

            <v-flex xs12 sm6>
                <UserList :value="item.users" @change="item.users = $event" />
            </v-flex>
            <v-flex xs12 sm6>
                <v-text-field v-model="item.contact" label="Contato" />
            </v-flex>

            <v-flex xs12 mt-4>
                <v-layout wrap>
                    <v-flex xs12 sm6 py-0>
                        <v-text-field v-model="item.cnpj" :rules="[rules.cnpj]" label="CNPJ" v-mask="'##.###.###/####-##'" return-masked-value class="my-0 py-0" />
                    </v-flex>
                    <v-flex xs12 sm6 py-0>
                        <v-text-field v-model="item.fitDay" :rules="[rules.range(1, 31)]" label="Dia de fechamento" v-mask="['#', '##']" return-masked-value class="my-0 py-0" />
                    </v-flex>
                </v-layout>
            </v-flex>
            <v-flex xs12 mt-4>
                <v-text-field v-model="item.cep" :rules="[rules.cep]" label="CEP" v-mask="'#####-###'" return-masked-value class="my-0 py-0" />
                <v-textarea v-model="item.address" label="Endereço" rows="3" class="my-0 py-0"></v-textarea>
                <v-select v-model="item.idCity" :rules="[rules.required]" :items="cityList" item-value="id" item-text="name" label="Cidade" />
            </v-flex>

            <v-flex xs12>
                <v-card color="primary" class="elevation-12">
                    <v-card-title class="text-center justify-center py-6">
                        <h1 class="font-weight-bold text-h4 white--text">Estrutura do condomínio</h1>
                    </v-card-title>
                    <div class="d-flex flex-no-wrap justify-space-between white">
                        <v-btn color="success" class="align-self-center ml-12" small @click="addBlock">
                            Adicionar Bloco
                            <v-icon right small> add_circle </v-icon>
                        </v-btn>
                        <v-switch class="align-self-center mr-12" v-model="item.fixedParkingSpace" inset label="Vagas Fixas" />
                    </div>
                    <v-tabs v-model="tab">
                        <v-tabs-slider color="primary"></v-tabs-slider>
                        <v-tab v-for="i in item.structure" :key="i">
                            {{ i.name }}
                        </v-tab>
                    </v-tabs>

                    <v-tabs-items v-model="tab">
                        <v-tab-item v-for="(i, index) in item.structure" :key="i" eager>
                            <v-card flat class="ma-12">
                                <v-layout wrap class="pa-0 ma-0">
                                    <v-flex xs12 class="text-right" pt-0>
                                        <v-btn small color="error" @click="removeBlock(index)" outlined>Deletar Bloco<v-icon right small> delete </v-icon></v-btn>
                                    </v-flex>
                                    <v-flex xs12>
                                        <v-text-field :label="$t('Nome')" v-model="i.name" dense />
                                    </v-flex>
                                </v-layout>
                                <Apartament v-if="i.apartments" :apartments="i.apartments" class="mt-12" />
                            </v-card>
                        </v-tab-item>
                    </v-tabs-items>
                </v-card>
            </v-flex>

            <v-flex xs12>
                <v-btn :loading="lock" :disabled="!valid" color="success" @click="validate" style="width: 100%;">Salvar</v-btn>
            </v-flex>
        </v-layout>
    </v-form>
</template>

<script>
import rules from "@/helpers/rules";
import Apartament from "./Apartament";
import UserList from "@/components/shared/UserList";

const itemClean = {
    name: null,
    fitDay: 20,
    fixedParkingSpace: false,
    structure: [
        {
            name: "ÚNICO",
            apartments: [],
        },
    ],
};

export default {
    components: {
        Apartament,
        UserList,
    },

    props: {
        itemToEdit: {
            type: Object,
        },
        lock: {
            type: Boolean,
            required: true,
        },
    },

    data() {
        return {
            rules,
            valid: true,
            item: JSON.parse(JSON.stringify(itemClean)),
            tab: null,
            cityList: [],
        };
    },

    watch: {
        itemToEdit: {
            immediate: true,
            handler(newVal) {
                if (this.$refs.form) {
                    this.$refs.form.reset();
                }

                this.$nextTick(() => {
                    if (newVal == null) {
                        this.item = JSON.parse(JSON.stringify(itemClean));
                    } else {
                        this.item = { ...newVal };
                    }
                });
            },
        },
    },

    methods: {
        removeBlock(index) {
            this.item.structure.splice(index, 1);
        },
        addBlock() {
            this.item.structure.push({
                name: `Bloco ${this.item.structure.length + 1}`,
                apartments: [{ name: "AP 1" }],
            });
        },
        validate() {
            if (this.$refs.form.validate()) {
                if (!this.item.structure || this.item.structure.length == 0) {
                    this.$eventHub.$emit("msgError", "Cadastre pelo menos um bloco.");
                } else {
                    this.$emit("save", this.item);
                }
            }
        },
    },

    mounted() {
        if (this.$refs.form) {
            this.$refs.form.reset();
        }
        this.$http
            .get("api/v2/city")
            .then((result) => {
                this.cityList = result;
            })
            .catch(() => {
                this.cityList = [];
            });
    },
};
</script>
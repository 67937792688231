<template>
    <v-autocomplete
        :value="value"
        @change="$emit('change', $event)"
        :items="list"
        :filter="customFilter"
        item-value="id"
        item-text="email"
        :label="$t('Sindico')"
        deletable-chips
        multiple
        small-chips
    >
        <template v-slot:item="data">
            <v-list-item-content>
                <v-list-item-title v-html="data.item.name"></v-list-item-title>
                <v-list-item-subtitle v-html="data.item.email"></v-list-item-subtitle>
            </v-list-item-content>
        </template>
    </v-autocomplete>
</template>

<script>
export default {
    props: {
        value: {
            required: true,
        },
    },

    data() {
        return {
            type: String,
            list: null,
        };
    },

    methods: {
        customFilter(item, queryText) {
            const searchText = queryText.toLowerCase();
            return (item.name && item.name.toLowerCase().indexOf(searchText) > -1) || (item.email && item.email.toLowerCase().indexOf(searchText) > -1);
        },
    },

    mounted() {
        this.$http
            .get(`api/v2/user`)
            .then((result) => {
                if (result) {
                    this.list = result.filter((i) => i.roles.includes("ADMIN_CONDOMINIUM"));
                }
            })
            .catch(() => {
                this.list = null;
            });
    },
};
</script>
<template>
    <v-simple-table>
        <template v-slot:default>
            <thead>
                <tr>
                    <th class="text-left pa-0">
                        <v-btn x-small color="success" @click="add">Adicionar Apartamento<v-icon x-small right> add_circle </v-icon></v-btn>
                    </th>
                    <th class="text-left">Nome</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(i, index) in apartments" :key="i">
                    <td>{{ index + 1 }}</td>
                    <td>
                        <v-text-field v-model="i.name" />
                    </td>
                    <td>
                        <v-btn small color="error" @click="remove(index)"><v-icon small> delete </v-icon></v-btn>
                    </td>
                </tr>
            </tbody>
        </template>
    </v-simple-table>
</template>

<script>
import { mask } from "vue-the-mask";
import rules from "@/helpers/rules";

export default {
    directives: { mask },

    props: {
        apartments: {
            type: Array,
        },
    },

    watch: {
        apartments: {
            immediate: true,
            handler(newVal) {
                this.$nextTick(() => {
                    if (newVal == null) {
                        this.numberLines = 1;
                    } else {
                        this.numberLines = this.apartments ? this.apartments.length : 1;
                    }
                });
            },
        },
    },

    data() {
        return {
            rules,
            numberLines: 1,
        };
    },

    methods: {
        add() {
            if (this.apartments) {
                this.numberLines++;
                let actual = this.apartments ? this.apartments.length : 0;
                for (let index = actual; index < this.numberLines; index++) {
                    this.apartments.push({ name: "AP " + this.numberLines });
                }
            }
        },
        remove(index) {
            if (this.apartments.length >= index) {
                this.apartments.splice(index, 1);
                this.numberLines--;
            }
        },
    },
};
</script>
